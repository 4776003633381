<template>
  <Default>
    <div class="joinus font-condensed" @mousemove="updateMousePosition">
      <div class="ju-banner">
        <div class="ju-banner-context common-content">
          <div class="common-transition-box">
            <transition name="transition-title">
              <h1 class="ju-banner-context-title" v-show="show">
                Join Us
              </h1>
            </transition>
          </div>
          <transition name="transition-text">
            <div class="ju-banner-context-text common-text-big primary" v-show="show">
              Want to change the world? We need you. Send your CV and introduction to
              <a href="mailto:contact@functionx.io" class="ju-banner-email">contact@functionx.io</a> with the position
              you are applying for as the title.
            </div>
          </transition>

          <div class="ju-banner-img ju-banner-robot"><img src="@/assets/images/join/join_xrobot@2x.png" alt="" /></div>
          <div class="ju-banner-img ju-banner-cv-1" ref="cv1">
            <img src="@/assets/images/join/joim_resume01@2x.png" alt="" />
            <div
              class="ju-banner-shadow"
              :style="{ transform: `translate(${cvShadowPosition[0][0]}px, ${cvShadowPosition[0][1]}px)` }"
            >
              <img src="@/assets/images/join/joim_resume01_bg@2x.png" alt="" />
            </div>
          </div>
          <div class="ju-banner-img ju-banner-cv-2" ref="cv2">
            <img src="@/assets/images/join/join_resume02@2x.png" alt="" />
            <div
              class="ju-banner-shadow"
              :style="{ transform: `translate(${cvShadowPosition[1][0]}px, ${cvShadowPosition[1][1]}px)` }"
            >
              <img src="@/assets/images/join/join_resume02_bg@2x.png" alt="" />
            </div>
          </div>
          <div class="ju-banner-img ju-banner-cv-3" ref="cv3">
            <img src="@/assets/images/join/join_resume03@2x.png" alt="" />
            <div
              class="ju-banner-shadow"
              :style="{ transform: `translate(${cvShadowPosition[2][0]}px, ${cvShadowPosition[2][1]}px)` }"
            >
              <img src="@/assets/images/join/join_resume03_bg@2x.png" alt="" />
            </div>
          </div>
          <div class="ju-banner-img ju-banner-cv-4"><img src="@/assets/images/join/joim_resume04@2x.png" alt="" /></div>
        </div>
      </div>
      <div class="ju-content common-content">
        <div class="ju-content-tabs">
          <div class="ju-content-tabs-btn">
            <CButton
              type="pill"
              :active="nowCountry === ''"
              :height="48"
              paddingLR="24px"
              minWidth="50px"
              @click="changeCountry('')"
              >View All</CButton
            >
          </div>
          <div class="ju-content-tabs-btn" v-for="item of countryList" :key="item">
            <CButton
              type="pill"
              :active="nowCountry === item"
              :height="48"
              paddingLR="24px"
              minWidth="50px"
              @click="changeCountry(item)"
              >{{ item }}</CButton
            >
          </div>
        </div>
        <transition-group class="ju-content-list" tag="div" name="join">
          <div class="ju-content-list-item" v-for="item of countryJoinSet" :key="item.position">
            <h3 class="ju-content-list-title">{{ item.position }}</h3>
            <div class="ju-content-list-country">
              <div class="ju-content-list-country-list" v-for="country of item.country" :key="country">
                <div class="ju-content-list-country-list-icon"><i class="common-icon-country"></i></div>
                {{ country }}
              </div>
            </div>
            <div class="ju-content-list-info" v-for="info of item.info" :key="info.title">
              <div class="ju-content-list-info-title font-bold" v-if="info.title !== ''">{{ info.title }}</div>
              <div class="ju-content-list-info-text common-text">
                <span v-for="(text, index) of info.context" :key="text"><br v-if="index !== 0" />{{ text }}</span>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </Default>
</template>

<script>
import Default from '@/components/layout/Default.vue'
import CButton from '@/components/common/CButton.vue'
import { gsapDestroy } from '@/tools/mixin'

export default {
  name: 'JoinUs',
  components: { CButton, Default },
  data() {
    return {
      show: false,
      setZero: false,
      mouseX: 0,
      mouseY: 0,
      cvCenter: [
        [0, 0],
        [0, 0],
        [0, 0]
      ],
      nowCountry: '',
      join: [
        {
          position: 'Marketing and Partnership Lead',
          country: ['Singapore', 'globally'],
          info: [
            {
              title: 'Job Scope',
              type: 'list',
              context: [
                'You will be tasked to lead the marketing effort with the council and sub council team of Function X. We are a global team with a flat reporting organisation. Apart from Function X (sub)councils, you will also be working closely with the team from Pundi X that contributes to Function X'
              ]
            },
            {
              title: 'What you’ll need',
              type: 'list',
              context: [
                'Minimally 2 years active in Crypto (either working or investing), Your original background may include technical, marketing or product roles, and if not marketing, now wishing to transition into Marketing. Essential that you have creative ideas eg: guerilla marketing, and a big plus if you’re already active on Crypto Twitter and other social media.',
                '- Market f(X) Wallet',
                '- Market Function X’s f(X)Core parent chain',
                '- Market our Function X’s block explorer Starscan',
                '- Partnerships with various ecosystems, including Cosmos Hub',
                '- Actively participate in the Function X forum in order to have a better understanding of our community and, in some cases, help direct community discussions.'
              ]
            },
            {
              title: '',
              type: 'list',
              context: ['Based in Singapore or globally.']
            }
          ]
        },
        {
          position: 'Head of PR',
          country: ['Singapore', 'globally'],
          info: [
            {
              title: 'Job Scope',
              type: 'list',
              context: [
                'Function X is building a Layer1 blockchain that supports IBC (Inter-Blockchain Communication Protocol) and EVM (Ethereum Virtual Machine). We are looking for top class marketers to build the story and spread the awareness of our blockchain and implementation to the world',
                'As a Head of PR, you are responsible to:',
                '- Involved in developing the story and strategy for Function X and online presence and shape our narrative and how we present our product, company, and mission to the world',
                '- Work closely with external partners to make joint announcements, build case studies, and infuse their voices into our campaigns',
                '- Assist in Translating the complexity of our product into concise pitches and stories that can be understood by audiences in and outside of the blockchain industry',
                '- Support writing press releases, blog posts, and partnership announcements and contribute to all our externally facing communication',
                '- Coordinate media appearances of our founders and other team members'
              ]
            },
            {
              title: 'What you’ll need',
              type: 'list',
              context: [
                '- Minimum 3 years of experience in crypto and has a strong interest of cryptocurrency/blockchain-related trends',
                '- Experience writing various externally facing communications - blog posts, social media posts, press releases, joint partnerships announcements, etc.',
                '- Experience in market research, messaging, positioning, and branding',
                '- Powerful storyteller: written, verbal, and visual',
                '- Adaptable and quick learner',
                '- Good team player who is comfortable working both with internal team members as well as other departments.'
              ]
            },
            {
              title: '',
              type: 'list',
              context: ['Based in Singapore or globally.']
            }
          ]
        },
        {
          position: 'Developer Relation',
          country: ['Singapore', 'globally'],
          info: [
            {
              title: 'Job Scope',
              type: 'list',
              context: [
                'Function X is building a Layer1 blockchain that supports IBC (Inter-Blockchain Communication Protocol) and EVM (Ethereum Virtual Machine). We are looking for top class developers to build the idea on our blockchain.',
                'As a Developer Relation, you are responsible to:',
                '- Increase adoption of Function X technology by working directly with our developer community to build more dapps, answer technical questions, and funnel insights back to the team to improve the overall experience and technology',
                '- Produce technical documentation, blogs, vlogs, and other easily digestible content for easy Developer onboarding',
                '- Work cross-functionally to help build out the DevRel function at Function X from the ground up',
                '- Be an active member of our developer community by helping to address technical questions and improve the overall quality of developer support in channels such as Discord and Github',
                '- Have an experience in making Developer Hackathon is a plus'
              ]
            },
            {
              title: 'What you’ll need',
              type: 'list',
              context: [
                '- Experience in developer education, developer relations, and technical writing',
                '- Strong written and communication skills with the ability to explain technical details to',
                '- technical and non-technical audiences',
                '- Experience writing blogs, code samples, demos, or tutorials',
                '- Experience with common developer and API tools such as SDKs, IDEs, API Design, etc',
                '- Experience of working within cross-functional teams'
              ]
            },
            {
              title: '',
              type: 'list',
              context: ['Based in Singapore or globally.']
            }
          ]
        },
        {
          position: 'Rockstar Memelord',
          country: ['globally'],
          info: [
            {
              title: 'Job Scope',
              type: 'list',
              context: [
                'You will be tasked to spread awareness about Function X in creative ways on Twitter, Tiktok, and other platforms. We make impossible things work. We think long term and we think big. Minimally 1 year active in Crypto.',
                'Part time position is open.'
              ]
            },
            {
              title: 'What you’ll need',
              type: 'list',
              context: [
                '- Have a large Twitter following of legitimate crypto Twitter users, preferably from the Cosmos ecosystem',
                '- Post multiple times per day on Twitter',
                '- Become one of the public faces of Function X',
                '- Contribute as a normal growth team member',
                '- Involve daily in the community building, including Telegram, Zealy, and Galxe',
                '- Active on Tiktok is a plus'
              ]
            }
          ]
        }
      ]
    }
  },
  mixins: [gsapDestroy],
  computed: {
    countryList() {
      const countrys = this.join.map(item => item.country)
      const list = countrys.flat()
      return [...new Set(list)]
    },
    countryJoin() {
      if (this.nowCountry === '') {
        return this.join
      }
      return this.join.filter(item => item.country.indexOf(this.nowCountry) !== -1)
    },
    countryJoinSet() {
      if (this.setZero) {
        return []
      }
      return this.countryJoin
    },
    cvShadowPosition() {
      const mouseMaxMove = 700
      const shadowMaxMove = 16
      const res = []
      for (let i = 0; i < this.cvCenter.length; i++) {
        const moveX = Math.max(-1, Math.min(1, (this.cvCenter[i][0] - this.mouseX) / mouseMaxMove))

        const moveY = Math.max(-1, Math.min(1, (this.cvCenter[i][1] - this.mouseY) / mouseMaxMove))
        res[i] = [moveX * shadowMaxMove, moveY * shadowMaxMove]
      }
      return res
    }
  },
  watch: {
    countryJoin() {
      this.setZero = true
      this.$nextTick(() => {
        this.setZero = false
      })
    }
  },
  methods: {
    changeCountry(country) {
      console.log('country', country)
      this.nowCountry = country
    },
    updateMousePosition(event) {
      this.mouseX = event.clientX
      this.mouseY = event.clientY
    }
  },
  mounted() {
    document.fonts.ready.then(() => {
      // 字体加载完成后的逻辑
      this.show = true
      this.$store.commit('setBridgeResourceReady', true)
      const cvDom = [this.$refs.cv1, this.$refs.cv2, this.$refs.cv3]
      for (let i = 0; i < cvDom.length; i++) {
        const rect = cvDom[i].getBoundingClientRect()
        this.cvCenter[i] = [rect.x + cvDom[i].offsetWidth / 2, rect.y + cvDom[i].offsetHeight / 2]
      }
    })
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/variable.scss';
$circle1: 964px * 1.2;
$circle1CenterXByViewportCenter: 569px; //1440 / 2 - ($circle1 / 2 + x)  => 1440 / 2 - (964px / 2 + -331px)
$circle1CenterY: 226px; //$circle1 / 2 + y  => 964px / 2 + (-256px)
$circle2: 494px * 1.2;
$circle2CenterXByViewportCenter: 223px;
$circle2CenterY: 113px;
$circle3: 816px * 1.2;
$circle3CenterXByViewportCenter: -565px;
$circle3CenterY: 119px;
$circle4: 396px * 1.2;
$circle4CenterXByViewportCenter: -374px;
$circle4CenterY: 352px;

.joinus {
  min-height: calc(100vh - $headHeight - $footHeight);
  padding-bottom: 200px;
  background-image: radial-gradient(circle at 50% 50%, #d3dfff 0%, rgba(211, 223, 255, 0) 50%),
    radial-gradient(circle at 50% 50%, #eee3ff 0%, rgba(238, 227, 255, 0) 50%),
    radial-gradient(circle at 50% 50%, #d3dfff 0%, rgba(211, 223, 255, 0) 50%),
    radial-gradient(circle at 50% 50%, #eee3ff 0%, rgba(238, 227, 255, 0) 50%);
  background-size: $circle1 $circle1, $circle2 $circle2, $circle3 $circle3, $circle4 $circle4;
  background-repeat: no-repeat;
  //background-position: calc(50% - 569px) -256px, calc(50% - 223px) -134px, calc(50% + 565px) -289px,
  //  calc(50% + 374px) 154px;
  background-position: calc(50% - $circle1CenterXByViewportCenter) #{$circle1CenterY - $circle1 / 2},
    calc(50% - $circle2CenterXByViewportCenter) #{$circle2CenterY - $circle2 / 2},
    calc(50% - $circle3CenterXByViewportCenter) #{$circle3CenterY - $circle3 / 2},
    calc(50% - $circle4CenterXByViewportCenter) #{$circle4CenterY - $circle4 / 2};
}
.ju-banner {
}
.ju-banner-context {
  padding-top: 88px;
  padding-bottom: 180px;
  max-width: 1176px;
  min-height: 441px;
  position: relative;
  z-index: 1;
}
.ju-banner-context-text {
  max-width: 500px;
}
.ju-banner-email {
  text-decoration: underline;
}

.ju-banner-img img {
  display: block;
  width: 100%;
}
.ju-banner-robot,
.ju-banner-cv-1,
.ju-banner-cv-2,
.ju-banner-cv-3,
.ju-banner-cv-4 {
  position: absolute;
}
.ju-banner-cv-1,
.ju-banner-cv-3,
.ju-banner-cv-2 {
  .ju-banner-shadow {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: translate(0px, 0px);
    transition: transform 0.3s linear;
  }
}
.ju-banner-cv-2 {
  .ju-banner-shadow {
    top: 8px;
  }
}
.ju-banner-robot {
  right: 0 + 16px;
  top: 246px;
  width: 213px;
  z-index: 10;
}
.ju-banner-cv-1 {
  width: 156px;
  top: 186px;
  right: 147px + 16px;
}
.ju-banner-cv-2 {
  width: 274px;
  top: 62px;
  right: 73px + 16px;
}
.ju-banner-cv-3 {
  width: 120px;
  top: 244px;
  right: 472px + 16px;
}
.ju-banner-cv-4 {
  width: 78px;
  top: 92px;
  right: 373px + 16px;
}

.ju-content {
  max-width: 1176px;
}
.ju-content-tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}
.ju-content-tabs-btn {
  & + .ju-content-tabs-btn {
    margin-left: 16px;
  }
}

.ju-content-list {
  position: relative;
  margin-top: -8px;
}

.ju-content-list-item {
  margin-top: 88px;
  max-width: 900px;
}
.ju-content-list-title {
}
.ju-content-list-country {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
}
.ju-content-list-country-list {
  padding-left: 8px;
  padding-right: 12px;
  min-height: 26px;
  background: #fbfbfb;
  border-radius: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: var(--font-sub);
  & + .ju-content-list-country-list {
    margin-left: 8px;
  }
}
.ju-content-list-country-list-icon {
  width: 16px;
  margin-right: 4px;
}
.ju-content-list-info {
  margin-top: 32px;
  & + .ju-content-list-info {
    margin-top: 24px;
  }
}
.ju-content-list-info-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.ju-content-list-info-text {
  margin-top: 8px;
}

.join-enter {
  opacity: 0;
  transform: translateY(20px);
}
.join-enter-to {
  opacity: 1;
  transform: translateY(0);
}
.join-enter-active {
  transition: opacity 0.6s, transform 0.6s;
}
.join-leave-to {
  opacity: 0;
}
.join-leave {
  opacity: 0;
}
</style>